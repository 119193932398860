// src/components/ProjectList.js
import React from 'react';
import { Container, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import projects from '../data/projects/projects.json';
import { Link } from 'react-router-dom';

const ProjectList = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        {projects.map((project) => (
          <Grid item xs={12} sm={6} md={4} key={project.id}>
            <Box>
              <img
                src={`/img/projects/${project.image}`}
                alt={project.title}
                style={{ width: '100%', height: 'auto' }}
              />

<Link to={`/project/${project.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  
               {project.title}
               </Link>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProjectList;
