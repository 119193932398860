import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import HalfByHalf from '../global/HalfByHalf';
import { useTranslation } from 'react-i18next';

const Competences = () => {
  const { t } = useTranslation('team');
  const competencesContent = t('competencesContent', { returnObjects: true });

  return (
    <HalfByHalf src="/img/team/competences.jpg" alt="" direction={"left"}>
      <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
        {t('competencesLabel')}
      </Typography>
      {competencesContent.map((paragraph, index) => (
        <Typography key={index} paragraph>
          {paragraph}
        </Typography>
      ))}
    </HalfByHalf>
  );
};

export default Competences;
