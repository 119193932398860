import { Box } from '@mui/material';
import React from 'react';

function LogoFooter(props) {
    return (
        <Box> 
            <img src='/img/logo_w.png' style={{ maxHeight:"150px"}} / >
        </Box>
    );
}

export default LogoFooter;