import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, Grid, useMediaQuery } from '@mui/material';
import theme from './theme';
import Navbar from './components/global/Navbar';
import Footer from './components/global/Footer';
import Home from './pages/Home';
import Offer from './pages/Offer';
import Bailouts from './pages/Bailouts';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import Recruitments from './pages/Recruitments';
import Team from './pages/Team';
import Clients from './pages/Clients';
import Project from './pages/Project';
import Experience from './pages/Experience';
import MobileNavbar from './components/global/MobileNavbar';
import Loader from './components/global/Loader';
import ScrollToTop from './functions/ScrollToTop';
import NotFound from './pages/NotFound';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleDOMLoad = () => {
      setLoading(false); 
    };

    const handleLoad = () => {
      setLoading(false); 
    };

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', handleDOMLoad);
    } else {
      console.log('handleDOMLoad();');
      handleDOMLoad(); 
    }

    window.addEventListener('load', handleLoad);

    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOMLoad);
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loading && <Loader />}
      {!loading && (
        <Router>
          <MediaQueryWrapper />
          <ScrollToTop />
        </Router>
      )}
    </ThemeProvider>
  );
};

const MediaQueryWrapper = () => {
  const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <>
      {!isMd && <MobileNavbar />}
      <Grid container sx={{ minHeight: '100vh' }}>
        {isMd && (
          <Grid item sx={{ width: '200px', boxShadow: "0 0 5px #ccc" }}>
            <Navbar />
          </Grid>
        )}
        
        <Grid
          item
          xs
          sx={{
            flexGrow: 1,
            maxWidth: '100%',
            [theme.breakpoints.up('md')]: {
              maxWidth: 'calc(100% - 200px) !important',
            },
          }}
        >
          <Box component="main" sx={{ p: "3rem" }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/offer" element={<Offer />} />
              <Route path="/team" element={<Team />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/recruitments" element={<Recruitments />} />
              <Route path="/bailouts" element={<Bailouts />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/project/:id" element={<Project />} />
              <Route path="/experience" element={<Experience />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
          <Footer />
        </Grid>
      </Grid>
    </>
  );
};

export default App;
