import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

// Styled component for the card
const Card = styled.div`
  width: 300px;
  height: 300px;
  overflow: hidden;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: transform 0.3s ease; /* Smooth scaling of the card */

`;

// Styled component for the image with zoom effect on hover
const CardImage = styled.div`
  height: 66.67%;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease; /* Smooth image zoom effect */

`;

// Styled component for the text container
const CardContent = styled.div`
  height: 33.33%; 
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Main component
const IndustryCard = ({ src, industryName }) => {
  return (
    <Card>
      <CardImage src={src} />
      
      <CardContent>
        <Typography variant="h6" align="center">
          {industryName}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default IndustryCard;
