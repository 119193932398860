import React from 'react';
import { Grid, Box } from '@mui/material';

const HalfByHalf = ({ src, direction, alt = 'Image', children }) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{
            my:'5rem',
          flexDirection: {
            xs: 'column', // Na mniejszych ekranach układ w kolumnie (jedno pod drugim)
            md: direction === 'left' ? 'row' : 'row-reverse', // Na większych ekranach zmieniamy kolejność na podstawie direction
          },
        }}
      >
        {/* Sekcja obrazu */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${src})`, // Dynamicznie przekazany adres obrazu
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              minHeight: '300px', // Zapewnia minimalną wysokość dla obrazu
              padding:'0',
            }}
          />
        </Grid>

        {/* Sekcja z zawartością */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%', // Zapewnia dopasowanie wysokości
              px: '2rem', 
            }}
          >
            {children}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default HalfByHalf;

