import React, { useState } from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import styled from 'styled-components';
import { useTheme } from '@mui/material/styles';

const FlippableCard = styled(Box)`
  perspective: 1000px;
  width: 350px;
  height: 350px; 
  display: flex;
  justify-content: center;
  align-items: center; 
`;

const FlippableInner = styled(Box)`
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
  transform: ${({ isFlipped }) => (isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)')};
`;

const FlippableFace = styled(Box)`
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const FrontFace = styled(FlippableFace)`
  background-color: #fff; 
`;

const BackFace = styled(FlippableFace)`
  transform: rotateY(180deg);
  background-color: #fff;
  padding: 1rem 2rem;
  overflow-y: auto;
  box-shadow: 0 0 5px #ccc;
`;

const List = styled.ul`
  list-style-type: none; /* Usuwa wypunktowanie */
  padding: 0; /* Usuwa domyślne wcięcie */
  margin: 0; /* Usuwa domyślną margines */
  font-size:0.8rem;
`;

const TeamCard = ({ name, role, title, details, avatar }) => {
  const theme = useTheme(); // Pobiera motyw MUI
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    setIsFlipped(!isFlipped);
  };

  const renderDetail = (detail) => {
    // Zamienia tagi <highlight> na stylizowany element z kolorem z motywu MUI
    return {
      __html: detail.replace(
        /<highlight>(.*?)<\/highlight>/g,
        (match, p1) => `<span style="font-size:1.5rem; font-weight: bold; color: ${theme.palette.primary.accent};">${p1}</span>`
      ),
    };
  };

  return (
    <FlippableCard onClick={handleCardClick}>
      <FlippableInner isFlipped={isFlipped}>
        {/* Front Face */}
        <FrontFace>
          <Avatar sx={{ width: 150, height: 150, mb: 5 }} src={`/img/team/${avatar}`} />
          <Typography gutterBottom variant="h6">
            {name}
          </Typography>
          <Typography>{role}</Typography>
        </FrontFace>
        {/* Back Face */}
        <BackFace>
        <Typography gutterBottom sx={{fontSize: "0.8rem"}}>
            {title}
          </Typography>
        <Typography gutterBottom variant="h7" sx={{fontWeight: "bold"}}>
            {name}
          </Typography>
          <List>
            {details.map((item, index) => (
              <li key={index} dangerouslySetInnerHTML={renderDetail(item)} />
            ))}
          </List>
        </BackFace>
      </FlippableInner>
    </FlippableCard>
  );
};

export default TeamCard;
