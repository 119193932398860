import React from 'react';
import { menuItems } from '../global/menuItems';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom'; // Importowanie Link z react-router-dom
import { useTranslation } from 'react-i18next';

const StyledButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  backgroundColor: 'transparent',
  border: 'none',
  padding: '0',
  color: '#fff',
  cursor: 'pointer',
  transition: 'all 0.3s ease',

  '&:hover svg': {
    transform: 'translateX(10px)',
  },

  '&:focus': {
    outline: 'none',
  },

  '& + &': {
    marginTop: '0.3rem', 
  },
}));

const Icon = styled('div')({
  display: 'inline-block',
  marginRight: '10px', 
  svg: {
    width: '18px', 
    height: '18px',
    fill: '#fff',
    transition: 'transform 0.3s ease',
  },
});

const StyledSpan = styled('span')({
  transition: 'transform 0.3s ease',
});

function MenuFooter() {
  const { t } =  useTranslation('navbar');
  return (
    <Box width="150px" margin="0 auto">
      <Typography variant='h5' sx={{marginBottom: "1rem", textAlign:"left"}}>Menu</Typography>
      {menuItems.map((item, index) => (
        <Link key={index} to={item.path} style={{ textDecoration: 'none' }}>
          <StyledButton>
            <Icon>
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff">
                <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z" />
              </svg>
            </Icon>
            <StyledSpan>{t(item.text)}</StyledSpan>
          </StyledButton>
        </Link>
      ))}
    </Box>
  );
}

export default MenuFooter;
