import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules'; 
import styled, { keyframes, css } from 'styled-components';
import { useTheme } from '@mui/material/styles';
import slides from '../../data/home/slider.json'; 

const zoomIn = keyframes`
  0% {
    transform: scale(5) rotate(5deg); 
  }
  100% {
    transform: scale(1) rotate(0deg); 
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  animation: ${props => props.animate ? css`${zoomIn} 8s ease forwards` : 'none'};
`;

const Slide = styled.div`
  position: relative;
  height: 500px; 
  overflow: hidden;
`;

const CustomPagination = styled.div`
  .swiper-pagination-bullet {
    background-color: white;
    opacity: 0.5;
    width: 8px;
    height: 8px;
  }

  .swiper-pagination-bullet-active {
    background-color: ${props => props.theme.palette.primary.main};
  }
`;

const CustomNavigation = styled.div`
  .swiper-button-next, .swiper-button-prev {
    color: white;
    width: 50px;
    height: 50px;
    &:after {
      font-size: 1rem;
    }
  }

  .swiper-button-next {
    right: 10px;
  }

  .swiper-button-prev {
    left: 10px;
  }
`;

const ImageCarousel = () => {
  const theme = useTheme();
  const [animate, setAnimate] = useState(true);

  const handleSlideChange = (swiper) => {
    setAnimate(false);
    setTimeout(() => {
      setAnimate(true);
    }, 50);
  };

  return (
    <Swiper
      modules={[EffectFade, Autoplay, Pagination, Navigation]} 
      effect="fade" 
      loop={true} 
      autoplay={{
        delay: 10000, 
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true, el: '.custom-pagination' }}
      navigation={{
        nextEl: '.custom-button-next',
        prevEl: '.custom-button-prev'
      }} 
      fadeEffect={{ crossFade: true }} 
      speed={0} 
      onSlideChange={handleSlideChange} 
      lazy // Umożliwia lazy loading
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <Slide>
            <StyledImage
              src={slide.image} 
              alt={slide.name}
              loading="lazy" // Lazy loading obrazów
              animate={animate}
            />
          </Slide>
        </SwiperSlide>
      ))}

      <CustomNavigation>
        <div className="swiper-button-prev custom-button-prev"></div>
        <div className="swiper-button-next custom-button-next"></div>
      </CustomNavigation>

      <CustomPagination>
        <div className="swiper-pagination custom-pagination"></div>
      </CustomPagination>
    </Swiper>
  );
};

export default ImageCarousel;
