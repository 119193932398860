import React from 'react';
import HalfByHalf from '../global/HalfByHalf';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StyledButton = styled('button')(({ theme }) => ({
    marginTop: '2rem',
    background: 'none',
    color: '#000',
    border: 'none',
    padding: '0',
    fontWeight: 'bold',
    fontSize: '1.1rem',
    fontFamily: '"Oswald", sans-serif',
    display: 'inline-flex',
    alignItems: 'center', 
    cursor: 'pointer',
    textTransform:'uppercase',
    '&:hover span': {
      transform: 'translateX(10px)', 
    },
    '& span': {
      display: 'inline-flex',
      alignItems: 'center',
      marginLeft: '10px',
      transition: 'all 0.3s ', 
    },
  }));

function BimHome(props) {
  const { t } = useTranslation('home');
    return (
        <HalfByHalf src={'/img/offer/bim/bim.jpg'} direction={'left'} >
          <Typography variant="h5" sx={{ marginBottom: '1rem' }}>
            {t('bimTitle')}
          </Typography>
          <Typography>
            {t('bimDescription')}
          </Typography>
          <Link to="/offer">
            <StyledButton>
              {t('learnMore')}
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000">
                  <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/>
                </svg>
              </span>
            </StyledButton>
          </Link>
        </HalfByHalf>
    );
}

export default BimHome;



