import React, { useEffect, useState } from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { ReactComponent as WorldMap } from '../../data/clients/worldLow.svg';
import './ClientsMap.css'; // Załaduj plik CSS
// import countries from '../../data/clients/mapData.json';
import FlagIcon from 'react-flagkit';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

// Styled component for the list of countries
const CountriesList = styled('ul')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)', // 3 kolumny
  listStyleType: 'none', // usuń markery
  padding: 0,
  margin: 0,
  gap: '10px', // odstępy między krajami
}));

// Styled component for each list item
const CountryItem = styled('li')({
  display: 'flex',
  alignItems: 'center', // wyrównaj flagę i nazwę w pionie
  fontWeight: 'normal',
  '&.highlighted': {
    fontWeight: 'bold', // pogrubienie podświetlonego kraju
  },
  cursor: 'pointer', // kursor jako wskazanie interaktywności
  transition: 'font-weight 0.3s ease',
});

const FlagWrapper = styled(Box)({
  marginRight: '10px', // odstęp między flagą a nazwą kraju
});

const ClientsMap = () => {
  const [highlightedCountry, setHighlightedCountry] = useState(null);

  // Function to handle hover on a country name
  const handleMouseEnter = (countryCode) => {
    setHighlightedCountry(countryCode);
  };

  // Function to handle leaving the hover
  const handleMouseLeave = () => {
    setHighlightedCountry(null);
  };

  const { t } = useTranslation('experience');
 const countries = t('countries', { returnObjects: true }) ;
  useEffect(() => {
    // Find all countries in the SVG and add event listeners
    const svgCountries = document.querySelectorAll('.worldMap path');
    svgCountries.forEach((country) => {
      const countryCode = country.getAttribute('id');

      if (countries.some((c) => c.short === countryCode)) {
        country.classList.add('highlighted');
        country.addEventListener('mouseenter', () => setHighlightedCountry(countryCode));
        country.addEventListener('mouseleave', handleMouseLeave);
      }
    });

    // Cleanup listeners on unmount
    return () => {
      svgCountries.forEach((country) => {
        country.removeEventListener('mouseenter', () => setHighlightedCountry(country.getAttribute('id')));
        country.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  useEffect(() => {
    const svgCountries = document.querySelectorAll('.worldMap path');
    
    svgCountries.forEach((country) => {
      const countryCode = country.getAttribute('id');
  
      // Usuwanie poprzedniej klasy podświetlenia
      country.classList.remove('highlighted-hovered');
  
      // Dodanie klasy, gdy kraj jest wyróżniony
      if (countryCode === highlightedCountry) {
        country.classList.add('highlighted-hovered');
      }
    });
  }, [highlightedCountry]);
  

  return (
    <Grid container sx={{ alignItems: 'center' }}>
      <Grid item xs={4}>
        <Typography sx={{mb:4}}>
        {t('mapDescription')}
        </Typography>

        {/* Lista krajów */}
        <CountriesList>
          {countries.map((country, index) => (
            <CountryItem
              key={index}
              onMouseEnter={() => handleMouseEnter(country.short)}
              onMouseLeave={handleMouseLeave}
              className={highlightedCountry === country.short ? 'highlighted' : ''}
            >
              <FlagWrapper>
                <FlagIcon country={country.short} size={25} />
              </FlagWrapper>
              {country.name}
            </CountryItem>
          ))}
        </CountriesList>

      </Grid>
      <Grid item xs={8} sx={{ paddingX: 5 }}>
        <Box className="map-container">
          <WorldMap style={{ width: '100%', height: 'auto' }} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ClientsMap;
