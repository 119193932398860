import React from 'react';
import './animated.css';
import { Box } from '@mui/material';

function Loader(props) {
    return (
        <Box sx={{width:"100%", height:'100%', position:'fixed', top:'0', background:'#fff', display:'flex', alignItems: "center", justifyContent: 'center'}}>
        <Box>
            <svg width="200px"
    height="100%" viewBox="0 0 61 116" version="1.1">
    <g>
        <path id="legs1"
            d="M32.551,48.865l1.57,-0.003c5.387,-0.019 12.456,0.084 17.653,-0.101c0.85,-1.147 6.318,-17.107 7.416,-18.653c-0.234,1.859 -0.673,3.688 -1.023,5.616l-2.081,11.148c-0.312,1.709 -0.85,3.857 -1.028,5.607c-6.004,0.149 -18.029,0.085 -18.029,0.085l-13.826,0.072l-0.708,0.012c-5.296,0.058 -12.113,0.192 -17.287,0.072c-0.327,-2.939 -1.599,-8.127 -2.205,-11.272c-0.353,-1.833 -2.155,-9.996 -2.117,-11.118c1.132,2.091 4.426,11.049 5.684,14.061c0.368,0.882 1.44,3.986 1.885,4.589l19.225,-0.096l4.871,-0.019Z" />
        <path id="legs2"
            d="M16.421,72.634c-0.697,0.274 -3.081,2.886 -4.01,3.668l-5.437,5.207c-1.89,1.885 -2.525,1.602 -2.524,3.658l-0.258,16.06c-0.399,-0.688 -3.44,-14.36 -3.951,-16.667c-0.442,-1.997 -0.354,-1.56 0.928,-2.691l5.236,-5.03c0.418,-0.428 0.639,-0.562 1.063,-0.943c1.057,-0.948 2.099,-2.186 3.154,-3.105l3.145,-2.866c0.707,-0.645 2.603,-2.532 2.603,-2.532l27.701,-0.134c0,-0 1.914,1.868 2.628,2.506l3.172,2.835c1.065,0.909 2.118,2.136 3.184,3.075c0.428,0.376 0.651,0.508 1.073,0.932l5.285,4.979c1.292,1.118 1.377,0.681 0.954,2.681c-0.489,2.312 -3.397,16.014 -3.789,16.705l-0.414,-16.057c-0.019,-2.056 -0.651,-1.766 -2.559,-3.633l-5.488,-5.154c-0.936,-0.772 -3.346,-3.362 -4.046,-3.628l-27.65,0.134Z" />
        <path id="legs3"
            d="M41.452,85.445c0.011,0.049 0.199,0.854 0.211,0.903c0.503,2.123 1.374,4.466 1.973,6.614c1.14,4.092 1.529,3.542 1.123,7.295c-0.235,2.169 -1.867,14.64 -1.702,15.718c0.033,-0.194 0.758,-2.045 0.912,-2.457c0.302,-0.807 0.616,-1.661 0.914,-2.475l3.655,-10.14c0.453,-1.223 0.626,-1.236 0.216,-2.545l-4.683,-15.746l-27.648,-0l-4.684,15.746c-0.41,1.309 -0.237,1.322 0.217,2.545l3.655,10.14c0.297,0.814 0.612,1.668 0.914,2.475c0.153,0.412 0.879,2.263 0.912,2.457c0.165,-1.078 -1.467,-13.549 -1.702,-15.718c-0.407,-3.753 -0.018,-3.203 1.123,-7.295c0.599,-2.148 1.47,-4.491 1.973,-6.614c0.012,-0.049 0.199,-0.854 0.211,-0.903l22.41,-0Z" />
        <path id="core"
            d="M23.348,56.188l-0.044,-3.53l4.495,-3.73c-0.857,-2.183 -2.192,-4.416 -3.114,-6.542c-0.373,-0.858 -2.788,-6.106 -3.146,-6.49c-0.18,-0.194 -0.475,-0.387 -0.676,-0.581c-0.251,-0.242 -0.368,-0.366 -0.601,-0.652l-13.512,-14.156c-0.348,-0.42 -0.214,-0.441 -0.238,-1.061l-0.593,-11.411c-0.042,-0.866 0.362,-1.221 0.709,-1.687l3.117,-4.146c0.817,-1.062 1.253,-1.716 2.584,-2.202c0.49,0.302 3.478,4.284 3.546,4.787c-1.452,1.195 -3.963,2.034 -5.657,3.13c0.399,0.428 1.74,0.955 2.353,1.26l4.74,2.4c0.717,0.377 1.629,0.759 2.22,1.222c-0.102,0.457 -1.704,1.641 -2.078,1.997c-0.709,0.674 -1.458,1.28 -2.204,1.927c-0.787,0.682 -1.519,1.376 -2.209,1.922c-0.556,0.44 -1.674,1.425 -2.177,1.923c0.501,0.339 4.374,0.965 5.312,1.147c0.912,0.177 4.841,0.823 5.266,1.129c0.263,0.361 2.571,11.386 2.828,12.843l11.08,0.001c0.616,-1.85 3.144,-12.145 3.671,-12.867c0.675,-0.603 9.377,-1.475 10.582,-2.248c-0.584,-0.557 -8.55,-7.264 -8.67,-7.774c1.103,-0.867 3.351,-1.801 4.654,-2.422c0.806,-0.386 1.546,-0.818 2.391,-1.241c0.737,-0.368 1.743,-0.723 2.272,-1.219c-1.514,-0.968 -4.376,-2.062 -5.659,-3.13c0.092,-0.661 1.25,-1.927 1.679,-2.495c0.621,-0.824 1.094,-1.512 1.742,-2.267c0.442,0.061 0.813,0.301 1.228,0.527c0.585,0.32 0.553,0.424 0.886,0.869c0.838,1.12 4.12,5.125 4.364,5.906c0.204,0.655 -0.499,11.394 -0.562,13.035c-0.738,0.404 -1.037,0.995 -1.543,1.568l-3.052,3.208c-0.937,1.089 -2.069,2.07 -3.081,3.179l-1.503,1.624c-1.412,1.579 -3.203,3.191 -4.592,4.8c-0.526,0.611 -1.141,0.902 -1.484,1.642c-0.676,1.459 -1.324,2.717 -2.044,4.225c-1.055,2.211 -3.174,6.343 -3.959,8.324l4.46,3.721l-0.008,3.535l6.949,0l0.005,26.42l-13.828,14.957l-13.828,-14.957l0.005,-26.42l6.924,0Z" />
    </g>
</svg>
        </Box>
        </Box>
    );
}

export default Loader;