import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import servicesData from '../../data/offer/services.json';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

// Styled components
const AccordionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 500px;
  overflow: visible;
`;

const AccordionItemContainer = styled.div`
  flex: ${props => (props.isOpen ? '5' : '1')}; /* Expand open item, others shrink */
  height: 100%;
  text-align: center;
  color: #000;
  box-sizing: border-box;
  padding: 0 1rem;
  position: relative;
  cursor: pointer;
  border-left: solid 1px;
  transition: 0.5s all;
`;

const AccordionTitle = styled(Typography)`
  margin: 0;
  transform-origin: 0 0; /* Point of rotation */
  display: ${props => (props.isOpen ? 'none' : 'block')}; /* Hide title when open */
  white-space: nowrap; /* Prevent line breaks */
  writing-mode: vertical-lr; /* Vertical text */
  transform: rotate(180deg) translate(-20px, -100%); /* Rotate and move */
  text-align: right;
  text-transform: uppercase;
`;

const AccordionContent = styled.div`
  opacity: ${props => (props.isOpen ? '1' : '0')};
  display: ${props => (props.isOpen ? 'block' : 'none')};
  max-height: ${props => (props.isOpen ? `${props.contentHeight}px` : '0')}; /* Dynamic height */
  overflow: visible;
  width: 100%; /* Ensure it takes full width */
  text-align: left;
  padding: 0; /* Remove padding inside the content */
  transition: 0.5s all;
`;

// Styled Typography with horizontal line before text
const StyledTypography = styled(Typography)`
  padding-left: 10px; /* Space for the line */
  margin-top: 0.5rem!important;
  // left:20px;
  
  &::before {
    content: '';
    position: absolute;
    left: 0;
    // top: 50%;
    transform: translateY(10px);
    width: 20px; /* Length of the line */
    height: 4px; /* Thickness of the line */
    background: none; /* Color of the line */
    border-bottom: solid 1px;
  }
`;

const AccordionItem = ({ title, items, isOpen, onClick }) => {
  const contentRef = useRef(null);

  // Dynamically set content height
  const contentHeight = contentRef.current ? contentRef.current.scrollHeight : 0;

  return (
    <AccordionItemContainer
      isOpen={isOpen}
      onClick={onClick}
    >
      <AccordionTitle variant="h6" isOpen={isOpen}>{title}</AccordionTitle>
      <AccordionContent
        isOpen={isOpen}
        contentHeight={contentHeight}
        ref={contentRef}
      >
        <Typography variant='h6'>{title}</Typography>
        
        {items.map((item, index) => (
          <StyledTypography key={index}>{item}</StyledTypography>
        ))}
        
      </AccordionContent>
    </AccordionItemContainer>
  );
};

const Services = () => {
  const [services, setServices] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const { t } = useTranslation('offer');
  const serviceKeys = Object.keys(t('services', { returnObjects: true }));

  useEffect(() => {
    setServices(servicesData);
  }, []);

  const handleItemClick = index => {
    setOpenIndex(prevIndex => (prevIndex === index ? null : index));
  };

  return (
    <>
    <Typography variant='h5' sx={{margin: "2rem 0"}}>
      {t('servicesLabel')}
    </Typography>
    <AccordionContainer>
        {serviceKeys.map((serviceKey, index) => (
          <AccordionItem
            key={index}
            title={t(`services.${serviceKey}.title`)}
            items={t(`services.${serviceKey}.items`, { returnObjects: true })}
            isOpen={openIndex === index}
            onClick={() => handleItemClick(index)}
          />
        ))}
      </AccordionContainer>
    </>
  );
};

export default Services;
