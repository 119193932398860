// src/components/ProjectDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import projectData from '../data/projects/projects.json';

const ProjectDetail = () => {
  const { id } = useParams();
  const project = projectData.find((proj) => proj.id === id);

  if (!project) {
    return <Typography variant="h6">Projekt nie znaleziony</Typography>;
  }

  return (
    <Container>
      <Typography variant="h4">{project.title}</Typography>
      <img
        src={`/img/projects/${project.image}`}
        alt={project.title}
        style={{ width: '100%',maxWidth:'900px', height: 'auto' }}
      />
      <Typography variant="body1">{project.description}</Typography>
    </Container>
  );
};

export default ProjectDetail;
