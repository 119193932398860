import React from 'react';
import styled from 'styled-components';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

// Styled components
const FullWidthImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0.5rem 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.5rem;
  &:before {
    content: '•';
    color: #007BFF;
    margin-right: 0.5rem;
  }
`;

const Sustainability = () => {
  const { t } = useTranslation('offer');
  const susData = t('sustainability', { returnObjects: true });

  return (
    <>
      <Typography variant="h5" sx={{ margin: '2rem 0' }}>
        {t('susLabel')}
      </Typography>
      <FullWidthImage src="/img/offer/sustainability/sustainability.jpg" alt="Sustainability" />

      <Grid container spacing={4}>
        {susData.map((item, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Typography variant="h6" sx={{ marginBottom: '1rem' }}>
              {item.title}
            </Typography>
            <List>
              {item.items.map((subItem, subIndex) => (
                <ListItem key={subIndex}>{subItem}</ListItem>
              ))}
            </List>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Sustainability;
