import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, EffectFade } from 'swiper/modules'; 
import styled, { keyframes, css } from 'styled-components';
import { Box, Typography } from '@mui/material';
import slides from '../../data/home/slider.json'; 

const zoomIn = keyframes`
  0% {
    transform: scale(5) rotate(15deg); 
  }
  100% {
    transform: scale(1) rotate(0deg); 
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  animation: ${css`${zoomIn} 22s ease forwards infinite`}; /* Usunięcie resetu animacji */
`;

const Slide = styled.div`
  position: relative;
  height: 500px; 
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Ciemna nakładka */
  z-index: 1;
`;

const Caption = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  text-align: center;
`;

const HomeCarousel = () => {
  return (
    <Box sx={{ position: 'relative', m: "-3rem", mb: '0rem' }}>
      <Swiper
        modules={[EffectFade, Autoplay]} 
        effect="fade" 
        loop={true} 
        autoplay={{
          delay: 20000, 
          disableOnInteraction: false,
        }}
        fadeEffect={{ crossFade: true }} 
        speed={2000} // Płynniejsze przejście
        lazy
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Slide>
              <StyledImage
                src={slide.image} 
                alt={slide.name}
                loading="lazy" 
              />
              <Overlay />
            </Slide>
          </SwiperSlide>
        ))}
      </Swiper>

      <Caption>
        <Typography variant='h2' sx={{ textTransform: "uppercase", my: 2 }}>
          Betonoki
        </Typography>
        <Typography variant='h4' sx={{ mb: "2rem" }}>
          Biuro projektowe
        </Typography>
      </Caption>
    </Box>
  );
};

export default HomeCarousel;
