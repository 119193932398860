import React from 'react';
import IndustryCard from './IndustryCard';
// import industriesData from "../../data/expirience/industries.json";
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FlexContainer = styled.div`
    width:100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; 
  gap: 2rem; 
  padding: 0 2rem; 
  margin: 2rem auto;
`;

const Industries = () => {
  const { t } = useTranslation('experience');
  const industriesData = t('industries', { returnObjects: true })
  return (
    <>
    <Typography variant='h2' sx={{my:5}}>
      {t('industrySectors')}
    </Typography>
    <Typography>
      {t('industrySectorsDescription')}
    </Typography>
    <FlexContainer>
      {industriesData.map((item, index) => (
        <IndustryCard key={index} src={item.image} industryName={item.name} />
      ))}
    </FlexContainer>
    </>
  );
}

export default Industries;
