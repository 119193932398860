// components/global/MobileNavbar.js
import React, { useState, useEffect} from 'react';
import { AppBar, Toolbar, IconButton, Drawer, Button, ListItem, ListItemText, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { menuItems } from './menuItems';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';

const MobileNavbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const { t, i18n } = useTranslation('navbar');
   useEffect(() => {
    const normalizedLanguage = i18n.language.split('-')[0]; 
    
    if (!normalizedLanguage || normalizedLanguage == 'pl') {
      i18n.changeLanguage('pl');
    } else {

      i18n.changeLanguage('en'); 
    }
  }, [i18n]);

  const handleLanguageToggle = () => {
    const newLang = i18n.language === 'pl' ? 'en' : 'pl';
    i18n.changeLanguage(newLang);
  };

  const logoSrc = i18n.language === 'pl' 
    ? '/img/logo_h_pl.svg' 
    : '/img/logo_h_en.svg'; 

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const list = () => (
    <Box sx={{ width: 250, paddingTop: 2, display:'flex', flexDirection:'column', height:"100%" }}>

      <IconButton onClick={toggleDrawer(false)} sx={{ alignSelf: 'flex-end', mb: 2 }}>
        <CloseIcon sx={{color: '#000'}}/>
      </IconButton>

      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} height={"100%"}>
      {menuItems.map((item, index) => (
        <ListItem button key={index} sx={{ justifyContent: 'flex-end' }}>
          <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
            <ListItemText primary={t(item.text)} />
          </Link>
        </ListItem>
      ))}
      </Box>

      <Box sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 0 }}>
  <LanguageIcon sx={{ fontSize: '1.2rem', color: theme.palette.text.primary, marginRight: 1 }} />

  <Button
    onClick={() => i18n.changeLanguage('pl')}
    sx={{
      color: i18n.language === 'pl' ? theme.palette.primary.accent : theme.palette.text.primary,
      // fontWeight: i18n.language === 'pl' ? 'bold' : 'regular',
      fontWeight:'regular',
      minWidth: 'auto',
      padding: 0,
      margin: 0,
      position: 'relative',
      overflow:'visible',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0, 
        height: '2px',
        width: '100%',
        backgroundColor: theme.palette.primary.accent,
        transform: i18n.language === 'pl' ? 'scaleX(1)' : 'scaleX(0)',
        transformOrigin: 'bottom left',
        transition: 'transform 0.3s ease',
      },
    }}
  >
    PL
  </Button>

  <Box sx={{ width: '1px', height: '1rem', backgroundColor: theme.palette.divider, mx: 0.5 }} />

  <Button
    onClick={() => i18n.changeLanguage('en')}
    sx={{
      color: i18n.language === 'en' ? theme.palette.primary.accent : theme.palette.text.primary,
      // fontWeight: i18n.language === 'en' ? 'bold' : 'regular',
      fontWeight:'regular',
      minWidth: 'auto',
      padding: 0,
      margin: 0,
      position: 'relative',
      overflow: 'visible',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: 0,
        height: '2px',
        width: '100%',
        backgroundColor: theme.palette.primary.accent,
        transform: i18n.language === 'en' ? 'scaleX(1)' : 'scaleX(0)',
        transformOrigin: 'bottom left',
        transition: 'transform 0.3s ease',
      },
    }}
  >
    ENG
  </Button>
</Box>




    </Box>
  );

  return (
    <>
      <AppBar sx={{ position: 'sticky', top: 0, bgcolor: '#fff', boxShadow: "0 0 5px #ccc" }}>
        <Toolbar sx={{ display: "flex", justifyContent: 'space-between' }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display:'flex', alignItems:'center', padding:'10px' }}>
            <img
              src={logoSrc}
              alt="Betonoki biuro projektowe"
              style={{
                width: '100%',
                maxHeight: '50px',
                height: 'auto',
              }}
            />
          </Link>

          <IconButton
            edge="start"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }} 
          >
            <MenuIcon sx={{color: '#000' }}/>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          height={'100%'}
        >
          {list()}
        </Box>
      </Drawer>
    </>
  );
};

export default MobileNavbar;
