// src/data/menuItems.js

export const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'Oferta', path: '/offer' },
    { text: 'Zespół', path: '/team' },
    { text: 'Doświadczenie', path: '/experience' },
    // { text: 'PROJEKTY', path: '/projects' },
    // { text: 'KLIENCI', path: '/clients' },
    // { text: 'REKRUTACJA', path: '/recruitments' },
    { text: 'Kontakt', path: '/contact' },
];
