import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import stepsData from '../../data/offer/steps.json';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
const Steps = () => {
  const { t } = useTranslation('offer');
  return (
    <Box sx={{ width: '100%', maxWidth: '100%', margin: '5rem auto' }}>
      <Typography variant='h3' textAlign="center" sx={{ marginBottom: "2rem" }}>
        {t('offerLabel')}
      </Typography>
      <Typography>
        {t('offerDescription')} 
      </Typography>

      <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start" columns={9} sx={{ marginTop: '2rem', position: 'relative' }}>
        {stepsData.steps.map((step, index) => (
          <React.Fragment key={index}>
            <Grid item lg={1} xs={9} textAlign="center" sx={{ position: 'relative', left: { xs: '0%', lg: 0 } }}>
              <Box
                display="flex"
                sx={{
                  flexDirection: { xs: 'row', lg: 'column' },
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  textAlign: { xs: 'left', lg: 'center' },
                }}
              >
                {/* ikona*/}
                <Box sx={{ marginRight: { xs: '10px', lg: 0 }, marginBottom: { lg: '10px' }, display:{ sm: 'block', lg: ' none'} }}>
                  <KeyboardArrowDownIcon  fontSize="medium"/>
                </Box>

                <Box 
                  sx={{ 
                    marginRight: { xs: '10px', lg: 0 }, 
                    marginBottom: { lg: '10px' }, 
                    display: { xs: 'none', lg: 'block' } 
                  }}
                >
                  <ArrowForwardIosIcon fontSize="medium" />
                </Box>

                {/* Tekst kroku */}
                <Box>
                  <Typography variant="h7">{t(`steps.${step.label}`)}</Typography>
                </Box>

                            {/* Linia pomiędzy krokami */}
            {index < stepsData.steps.length - 1 && (
              <Box
                sx={{
                  position: { xs: 'absolute', lg: 'absolute' },
                  width: { xs: '2px', lg: '100%' },
                  height: { xs: '100%', lg: '3px' },
                  backgroundColor: '#000',
                  top: { xs: '50px', lg: '26px' }, 
                  left: { xs: '27px', lg: `calc(100% + 12px)` },
                  transform: { xs: 'translateY(-50%)', lg: 'translateX(-50%)' },
                  marginTop: { xs: '5px', lg: '0' }
                }}
              />
            )}
              </Box>
            </Grid>


          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

export default Steps;
