import React from 'react';
import { Typography, Grid } from '@mui/material';
import styled from 'styled-components';

// Styled component for the image
const FullHeightImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
  object-position: center; /* Centers the image */
`;

const Virtual = () => {
  return (
    <>
      <Typography variant="h5" sx={{ margin: '2rem 0' }}>
        Wirtualna rzeczywistość
      </Typography>
      <Grid container spacing={4} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Typography paragraph>
            Wirtualna rzeczywistość umożliwia symulację projektowanej przestrzeni w różnych wariantach i kontekstach. W ramach naszych usług oferujemy klientom:
          </Typography>
          <ul>
            <li>Modele 3D i wizualizacje bryły projektowanego budynku w przestrzeni.</li>
            <li>Projekty wnętrz i wizualizacje aranżacji pomieszczeń.</li>
            <li>Przygotowanie dedykowanych wizualizacji na potrzeby marketingowe i ekspozycyjne, takich jak prezentacje wirtualne, panoramy 360, oraz filmy 360.</li>
            <li>Rozszerzenie wizualizacji o możliwość interaktywnego spaceru po wybranych wnętrzach.</li>
            <li>Możliwość prezentacji przestrzeni wirtualnej z użyciem okularów VR Oculus, np. na pokazach i targach.</li>
          </ul>
        </Grid>
        <Grid item xs={12} md={6}>
          <FullHeightImage
            src="/img/offer/virtual/virtual.jpg"
            alt="BIM"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Virtual;
