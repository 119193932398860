import { Box, Typography } from '@mui/material';
import React from 'react';

function Copyright(props) {
    return (
        <Box sx={{ bgcolor: 'primary.accent', color: 'white', p: "0.1rem", mt: 'auto' }}>
        <Typography variant="body2" align="center">
          &copy; {new Date().getFullYear()} Nikaj Studio. All rights reserved.
        </Typography>
      </Box>
    );
}

export default Copyright;