import { Box, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Styled component dla podpunktów z ikoną
const StyledListItem = styled(Typography)(({ theme }) => ({
  position: 'relative',
  paddingLeft: '2rem',
  '&::before': {
    content: '"✓"',  // Ikona tick
    position: 'absolute',
    left: 0,
    color: '#fff',
    fontSize: '1.5rem',
  },
}));

// Styled component dla przycisku
const SeeMore = styled('button')(({ theme }) => ({
  marginTop: '2rem',
  background: 'none',
  color: '#fff',
  border: 'none',
  padding: '0',
  fontWeight: 'bold',
  fontSize: '1.1rem',
  fontFamily: '"Oswald", sans-serif',
  display: 'inline-flex', 
  alignItems: 'center',
  cursor: 'pointer',
  textTransform: 'uppercase',
  '&:hover span': {
    transform: 'translateX(10px)',
  },
  '& span': {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: '10px',
    transition: 'all 0.3s ',
  },
}));

const SustainibilityHome = (props) => {
    const { t } = useTranslation('home');
    return (
        <Box
        height={'auto'}
        display={'flex'}
        alignItems={'center'}
        sx={{
            backgroundImage: 'url(/img/offer/sustainability/sustainability.jpg)',
            backgroundSize: 'cover',  
            backgroundPosition: 'center',  
            backgroundRepeat: 'no-repeat',  
            color: 'white',  
            mx: '-3rem',
            justifyContent: { xs: 'center', md: 'flex-start' },

        padding:{xs:'2rem', md:'5rem'}
        }}
    >
    
            <Box sx={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '2rem' }}>
                <Typography variant='h4' sx={{ marginBottom: '2rem' }}>
                    {t('susLabel')}
                </Typography>

                
                <StyledListItem variant='h5'>
                    {t('energyEfficiency')}
                </StyledListItem>

                <StyledListItem variant='h5'>
                    {t('auditsCertifications')}
                </StyledListItem>

                <StyledListItem variant='h5'>
                    {t('reportingObligations')}
                </StyledListItem>
                <Link to="/offer">
                    <SeeMore>
                        {t('seeMore')}
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#fff">
                                <path d="m560-240-56-58 142-142H160v-80h486L504-662l56-58 240 240-240 240Z"/>
                            </svg>
                        </span>
                    </SeeMore>
                </Link>
            </Box>
        </Box>
    );
}

export default SustainibilityHome;
