import { Typography } from '@mui/material'
import React from 'react';
import ClientsMap from '../components/clients/ClientsMap';
import Industries from '../components/experience/Industries';
import { useTranslation } from 'react-i18next';

function Experience(props) {
    const { t } = useTranslation('experience');
    return (
        <>
            <Typography variant='h2' sx={{mb:2}}>
                {t('ourExperience')}
                </Typography>            
            <ClientsMap/>
            <Industries/>

        </>
    );
}

export default Experience;