import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import navbar_pl from './pl/navbar.json';
import navbar_en from './en/navbar.json';
import footer_pl from './pl/footer.json';
import footer_en from './en/footer.json';
import contact_pl from './pl/contact.json';
import contact_en from './en/contact.json';
import home_pl from './pl/home.json';
import home_en from './en/home.json';
import offer_pl from './pl/offer.json';
import offer_en from './en/offer.json';
import team_pl from './pl/team.json';
import team_en from './en/team.json';
import experience_pl from './pl/experience.json'
import experience_en from './en/experience.json'
import notFound_pl from './pl/notFound.json';
import notFound_en from './en/notFound.json';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        navbar: navbar_en,
        footer: footer_en,
        contact: contact_en,
        home: home_en,
        offer: offer_en,
        team: team_en,
        experience: experience_en,
        notFound: notFound_en
      },
      pl: {
        navbar: navbar_pl,
        footer: footer_pl,
        contact: contact_pl,
        home: home_pl,
        offer: offer_pl,
        team: team_pl,
        experience: experience_pl,
        notFound: notFound_pl

      }
    },
    fallbackLng: 'pl',
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie']
    },
    interpolation: {
      escapeValue: false,
    }
  });
  