import { createTheme } from '@mui/material/styles';

// Definiowanie zmiennych kolorów
const colors = {
  dark: '#2d2d2d',
  light: '#f0f0f0',
  // accent: '#f8ae4a',
  accent: '#B8B8B0',
  accentDark: '#D98B26',
};

const theme = createTheme({
  typography: {
    fontFamily: '"Raleway", sans-serif', // Ustawienie Raleway jako domyślnej czcionki
    h1: {
      fontFamily: '"Oswald", sans-serif', // Ustawienie Oswald dla nagłówków
    },
    h2: {
      fontFamily: '"Oswald", sans-serif', // Ustawienie Oswald dla nagłówków
    },
    h3: {
      fontFamily: '"Oswald", sans-serif', // Ustawienie Oswald dla nagłówków
    },
    h4: {
      fontFamily: '"Oswald", sans-serif', // Ustawienie Oswald dla nagłówków
    },
    h5: {
      fontFamily: '"Oswald", sans-serif', // Ustawienie Oswald dla nagłówków
    },
    h6: {
      fontFamily: '"Oswald", sans-serif', // Ustawienie Oswald dla nagłówków
    },
    h7: {
      fontFamily: '"Oswald", sans-serif', // Ustawienie Oswald dla nagłówków
    },
    // Reszta nagłówków...
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ':root': {
          '--color-dark': colors.dark,
          '--color-light': colors.light,
          '--color-accent': colors.accent,
          '--color-accent-dark': colors.accentDark,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '0 0.5rem',
          padding: '0.5rem',
          background: 'none',
          fontWeight: 'bold',
          fontFamily: '"Oswald", sans-serif',
          color: colors.dark,
          position: 'relative',
          overflow: 'hidden', 
          borderRadius: 0,
          fontSize:"1rem",
          '&:hover': {
            background: 'none!important',
          },
          '&:focus': {
            background: 'none',
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: 0,
            height: '2px',
            width: '100%',
            backgroundColor: colors.accent,
            transform: 'scaleX(0)',
            transformOrigin: 'bottom right',
            transition: 'transform 0.5s ease', 
          },
          '&:hover::after': {
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left',
          },
          '&:active': {
            backgroundColor: colors.dark,
            boxShadow: '0px 6px 8px rgba(0,0,0,0.3)',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.dark,
      light: colors.light,
      accent: colors.accent,
      accentDark: colors.accentDark,
    },
    secondary: {
      main: colors.accent,
    },
  },
});

export default theme;
