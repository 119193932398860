import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import LogoFooter from '../footer/LogoFooter';
import Copyright from '../footer/Copyright';
import ContactFooter from '../footer/ContactFooter';
import MenuFooter from '../footer/MenuFooter';

const Footer = () => {
  return (
    <footer>
      <Grid
        container
        spacing={0}
        sx={{
          bgcolor: 'primary.dark',
          color: 'white',
          py: '2rem',
          mt: 'auto',
          alignItems: 'stretch',
          width: '100%', 
          margin: 0,
        }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <LogoFooter />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{ textAlign: 'center', width: '100%' }} 
        >
          <MenuFooter />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          sx={{ textAlign: 'center', width: '100%' }} 
        >
          <ContactFooter />
        </Grid>
      </Grid>

      <Copyright />
    </footer>
  );
};

export default Footer;
