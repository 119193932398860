import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './assets/fonts/fonts.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from 'styled-components'; // Importuj ThemeProvider
import theme from './theme'; // Importuj swój plik motywu
import './locales/i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
