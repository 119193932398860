import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import styled, { keyframes, css } from 'styled-components';

// Zdefiniowanie animacji oddalania
const zoomOut = keyframes`
  from {
    transform: scale(1.5); /* Początkowe przybliżenie */
  }
  to {
    transform: scale(1); /* Pełne oddalenie */
  }
`;

// Stylowanie obrazu z animacją
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  ${props =>
    props.animate &&
    css`
      animation: ${zoomOut} 5s ease forwards; /* Użycie helpera css */
    `}
`;

const images = [
  '/img/expirience/industries/DJI_0254_2.jpg',
  '/img/expirience/industries/DJI_0254.jpg',
  '/img/expirience/industries/Obraz049.jpg',
];

const ImageSlider = () => {
  const [animate, setAnimate] = useState(true); // Stan animacji
  const [activeIndex, setActiveIndex] = useState(0); // Stan aktywnego slajdu

  const handleSlideChange = (swiper) => {
    setAnimate(false); // Zatrzymaj animację
    setActiveIndex(swiper.activeIndex); // Ustaw aktywny slajd

    // Wymuszamy ponowne uruchomienie animacji
    setTimeout(() => {
      setAnimate(true); // Ustaw ponownie animację
    }, 0); // Użyj 0ms, aby wymusić ponowne renderowanie
  };

  return (
    <Swiper
      modules={[EffectFade, Autoplay, Pagination, Navigation]} // Dodanie modułów tutaj
      effect="fade" // Ustawiamy fade jako efekt przejścia
      loop={true} // Infinite loop
      autoplay={{
        delay: 5000, // Automatyczna zmiana co 5 sekund
        disableOnInteraction: false,
      }}
      pagination={{ clickable: true }} // Paginacja
      navigation={true} // Strzałki nawigacji
      fadeEffect={{ crossFade: true }} // Efekt fade
      speed={1000} // Czas trwania animacji przejścia
      onSlideChange={handleSlideChange} // Wywołanie funkcji przy zmianie slajdu
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <StyledImage src={image} alt={`Slider Image ${index + 1}`} animate={index === activeIndex && animate} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ImageSlider;
