import React, { useState, useEffect } from 'react';
import { useTransition, animated } from '@react-spring/web';
import { useTranslation } from 'react-i18next';
import slides from '../../data/home/slider.json';
import { Box, Typography } from '@mui/material';

const HomeCarouselSimple = () => {
  const { t } = useTranslation('home');
  const [index, setIndex] = useState(0);
  const [isFirstSlide, setisFirstSlide] = useState(true);

  const imageTransitions = useTransition(index, {
    from: { opacity: isFirstSlide ? 1 : 0, transform: 'scale(2) rotate(10deg)' },
    enter: { opacity: 1, transform: 'scale(1) rotate(0)' },
    leave: { opacity: 0, transform: 'scale(2) rotate(10deg)' },
    config: { duration: 10000, easing: (t) => t * (2 - t) },
  });

  const textTransitions = useTransition(index, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 1000 },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setisFirstSlide(false);
      setIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 12000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ position: 'relative', height: '500px', overflow: 'hidden', m: '-3rem', mb: 0 }}>
      {imageTransitions((style, i) => (
        <animated.div style={{ ...style, position: 'absolute', width: '100%', height: '100%' }}>
          <img
            src={slides[i].image}
            alt={slides[i].name}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </animated.div>
      ))}

      {/* Niezależne przejście tekstu */}
      {textTransitions((style, i) => (
        <animated.div style={{ ...style, position: 'absolute', bottom: '0%', left: '0%', width:'100%' }}>
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              padding: '1rem 2rem',
              borderRadius: '4px',
            }}
          >
            <Typography variant="h6" align="center">{t(`slider.${i}.name`)}</Typography>
          </Box>
        </animated.div>
      ))}
    </Box>
  );
};

export default HomeCarouselSimple;
